<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/sales`"
                title="Sales"
                :columns="columns"
                routerpath="/crm/sales/addsales"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :bulksales="true"
                :canDelete="canDelete"
                viewComponent="salesshow"
                :newPage="true"
              >
                <template #action="{ row, getGridData }">
                  <div class="d-flex">
                    <div class="d-flex">
                      <b-badge
                        v-if="approvalAccess && row.approved1 !== 'Approved'"
                        variant="primary"
                        :id="'sales-approve' + row.id"
                        class="mr-50 cursor-pointer"
                        @click="approveRow(row, 'Approved', getGridData)"
                      >
                        <feather-icon icon="CheckIcon" />
                      </b-badge>
                      <b-tooltip
                        v-if="approvalAccess && row.approved1 !== 'Approved'"
                        :target="'sales-approve' + row.id"
                        triggers="hover"
                      >
                        Approve
                      </b-tooltip>

                      <b-badge
                        v-if="
                          approvalAccess &&
                          row.receivedamount == 0 &&
                          row.approved1 != 'Reject'
                        "
                        variant="danger"
                        :id="'sales-reject' + row.id"
                        class="mr-50 cursor-pointer"
                        @click="approveRow(row, 'Rejected', getGridData)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-badge>

                      <b-tooltip
                        v-if="
                          approvalAccess &&
                          row.receivedamount == 0 &&
                          row.approved1 != 'Reject'
                        "
                        :target="'sales-reject' + row.id"
                        triggers="hover"
                      >
                        Reject
                      </b-tooltip>
                    </div>
                  </div>
                </template>
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'duedate'">
                    {{
                      props.row.paymenttermday
                        ? moment(props.row.bookingdate, "DD/MM/YYYY")
                            .add(parseFloat(props.row.paymenttermday), "days")
                            .format("DD/MM/YYYY")
                        : ""
                    }}
                  </span>
                  <span v-else-if="props.column.field === 'plotareasqyard'">
                    {{ twoDecimal(props.row.plotareasqfeet / 9) }}
                  </span>
                  <span v-else-if="props.column.field === 'plcharges'">
                    {{ twoDecimal(props.row.gardenphasevalue + cornerphasevalue) }}
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import approveReject from "@/components/approveReject.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import moment from "moment";
import { BBadge, BTooltip } from "bootstrap-vue";
import axios from "axios";
export default {
  components: {
    GoodTableColumnSearch,
    BTooltip,
    approveReject,
    BBadge,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      moment,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Sales" : "Add Sales"}`,
        submitRouterPath: "/crm/sales",
        method: "post",
        action: "add",
        url: `${baseApi}/sales`,
        gridForm: true,
        inputFields: [],
      },
      columns: [
        {
          label: "User",
          field: "team_data",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          searchField: "team",
          filterOptions: {
            enabled: true,
            placeholder: "Search  User",
          },
        },
        {
          label: "Customer",
          field: "cfirstname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Customer",
          },
        },
        {
          label: "Booking Date",
          field: "bookingdate",
          type: "datetime",

          filterOptions: {
            enabled: true,
            placeholder: "Search Booking Date",
          },
        },
        {
          label: "Project",
          field: "project",
          type: "dropdown",
          url: "getProject",
          responseValue: "projectname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },

        {
          label: "Plot No",
          field: "plotno.plotno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Plot No",
          },
        },
        {
          label: "Plot Size (Yard)",
          field: "plotareasqyard",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Plot Rate",
          field: "plotrate",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Plot Value",
          field: "plotvalue",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "PLC Charges",
          field: "plotvalue",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },

        {
          label: "Discount",
          field: "discount",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Plot Deal Amount",
          field: "totalpayment",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Received Amount",
          field: "receivedamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Balance Amount",
          field: "balanceamount",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Payment Terms Day",
          field: "paymenttermday",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Due date",
          field: "duedate",
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Status",
          field: "approved1",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      approvalAccess: false,
    };
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Sales") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
    this.getAccess();
  },
  watch: {
    "$store.state.app.user_approval": {
      handler: "getAccess",
      immediate: true,
    },
  },
  methods: {
    getAccess() {
      this.approvalAccess = this.$store.getters["app/approvalAccess"]("Sales");
    },
    async approveRow(row, status, getGridData) {
      this.$swal({
        title: `Are you sure to ${status == "Approved" ? "Approve" : "Reject"}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${status == "Approved" ? "Approve" : "Reject"}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          const data = {
            approved1: status,
          };
          if (status == "Approved") {
            data.approved1datetime = moment(new Date()).format();
          }
          if (result.value) {
            await axios({
              method: "put",
              url: `${this.baseApi}/sales/${row.id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              data: JSON.stringify(data),
            })
              .then((json) => {
                if (json.data.status == 200 || json.data) {
                  this.$swal({
                    icon: "success",
                    title: `${status == "Approved" ? "Approved" : "Rejected"}`,
                    text: `Successfully ${
                      status == "Approved" ? "Approved" : "Rejected"
                    }.`,
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  getGridData();
                }
              })
              .catch((error) =>
                this.$swal({
                  title: "Error!",
                  text: `${error.response.data.message}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                })
              );
          }
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
